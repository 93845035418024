<template>
<div>
    <div>
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners" style="position: fixed;"/>
        <b-row>
            <b-col>
                <applicationNoteManagement/>
                <b-card :hidden="isLoader==true">
                    <b-tabs>
                        <b-tab title="Suggested Course">
                            <b-row>
                            <b-col md="4">
                                <b-form-input placeholder="Search..." v-model="filter"/>
                            </b-col>
                            <b-col md="8">
                                <div class="content-header-right text-md-right d-md-block d-none mb-1 top_btn_right">
                                <b-button @click="!isLoader ? saveShorlistedColleges() : ''" :variant="!isLoader ? 'primary' : 'secondary'" class="btn btn-icon">
                                    <span v-if="isLoader"><b-spinner/></span>
                                    <span v-else>
                                        Move to Shortlist
                                    </span>
                                </b-button>
                                </div>
                            </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <small class="text-danger" v-show="errorShortList!==''">{{errorShortList}}</small>
                                    <b-table
                                        :fields="columns"
                                        :items='shorlistedCollege'
                                        show-empty
                                        empty-text="No matching records found"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                        responsive
                                        class="student_app_sCollage position-relative"
                                    >
                                    <!-- <template #head(isSelect)="">
                                        <input type="checkbox" v-if="!application.applicationClose && !checkAllClgStatuses()" value="true" v-model="allSelect" @change="selectAll()"/>
                                    </template>       -->
                                    <template #cell(isSelect)="data">
                                        <input type="checkbox" value='true' v-if="!application.applicationClose && data.item.clgStatus=='pending' || data.item.clgStatus==''" @change="suggestedChange()" v-model="data.item.isSelect"/>
                                        <img 
                                            src="@/assets/images/erflog/info_icon.png"
                                            alt="info" 
                                            v-else
                                            v-b-tooltip.hover.top="'Application is in progress.'"
                                        />
                                    </template>
                                    <template #cell(intake)="data">
                                        <span v-for="(ele,index) in data.item.intake" :key="index">
                                            {{ele}}<br>
                                        </span>
                                    </template>
                                    <template #cell(applicationDeadLine)="data">
                                        <span v-for="(ele,index) in data.item.applicationDeadLine" :key="index">
                                            {{ele.disDate}}<br>
                                        </span>
                                    </template> 
                                    <template #cell(examsScores)="data">
                                        <span v-for="(ele,index) in data.item.examsScores" :key="index">
                                            {{ele.title}}&nbsp;&nbsp; {{ele.score}}<br>
                                        </span>
                                    </template>      
                                    <template #cell(remark)="data">
                                        <span v-if="data.item.remark" :title="data.item.remark" class="remarktext__elisis">{{data.item.remark}}</span>
                                        <span v-else>--</span>
                                    </template>                              
                                    <template #cell(germanLanguage)="data">
                                        <span v-if="data.item.germanLanguage" :title="data.item.germanLanguage" class="remarktext__elisis">{{data.item.germanLanguage}}</span>
                                        <span v-else>--</span>
                                    </template>                              
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab title="Shortlist Course">
                            <b-table
                            :fields="shortListcolumns"
                            :items='allCollegeShortlist'
                            show-empty
                            empty-text="No matching records found"
                             responsive
                            class="student_app_sCollage position-relative"                                 
                            >
                            <!-- <template #cell(applicationDeadLine)="data">
                                <span v-for="(ele,index) in data.item.applicationDeadLine" :key="index">
                                    {{ele.disDate}}<br>
                                </span>
                            </template> 
                            <template #cell(examsScores)="data">
                                <span v-for="(ele,index) in data.item.examsScores" :key="index">
                                    {{ele.title}}&nbsp;&nbsp; {{ele.score}}<br>
                                </span>
                            </template>
                             <template #cell(no)="data">
                                <span>
                                   {{Number(data.index + 1)}}
                                </span>
                            </template>  -->
                            <template #cell(isSelect)="data">
                                <img src='@/assets/images/erflog/Approved.png' style="margin-right: 5px;" v-if="application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId"/>
                            </template> 
                            <template #cell(applicationDeadLine)="data">
                                <span v-for="(ele,index) in data.item.applicationDeadLine" :key="index" :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ele.disDate}}<br>
                                </span>
                            </template> 
                            <template #cell(examsScores)="data">
                                <span v-for="(ele,index) in data.item.examsScores" :key="index" :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ele.title}}&nbsp;&nbsp; {{ele.score}}<br>
                                </span>
                            </template>
                            <template #cell(sortListDate)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.sortListDate }}
                                </span>
                            </template>
                            <template #cell(modeofApp)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.modeofApp }}
                                </span>
                            </template>
                            <template #cell(uniName)="data">
                                <div style="display: flex;align-items: center;">
                                    <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                        {{ data.item.uniName }}
                                    </span>
                                </div>
                            </template>
                            <template #cell(courceName)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.courceName }}
                                </span>
                            </template>
                            <template #cell(fieldName)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.fieldName }}
                                </span>
                            </template>
                            <template #cell(cllgName)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.cllgName }}
                                </span>
                            </template>
                            <template #cell(applicationFee)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.applicationFee }}
                                </span>
                            </template>
                            <template #cell(tutionFee)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                    {{ data.item.tutionFee }}
                                </span>
                            </template>
                             <template #cell(no)="data">
                                <span :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}">
                                   {{Number(data.index + 1)}}
                                </span>
                            </template> 
                            <template #cell(remark)="data">
                                <span v-if="data.item.remark" :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}" :title="data.item.remark" class="remarktext__elisis">{{data.item.remark}}</span>
                                <span v-else>--</span>
                            </template>  
                            <template #cell(germanLanguage)="data">
                                <span v-if="data.item.germanLanguage" :class="{addUniqClass: application.finalSelected && data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId}" :title="data.item.germanLanguage" class="remarktext__elisis">{{data.item.germanLanguage}}</span>
                                <span v-else>--</span>
                            </template>  
                            <template #cell(applicationStatus)="data">
                               <span 
                                :class="[{'badge badge-primary': (data.item.application_Status.toLowerCase()=='pending'), 
                                'badge badge-success': (data.item.application_Status.toLowerCase()=='admissionsuccessful'), 
                                'badge badge-dark': (data.item.application_Status.toLowerCase()=='applicationsubmitted'), 
                                'applicationSubmitted': (data.item.application_Status.toLowerCase()=='applicationsubmitted'), 
                                'badge badge-warning':(data.item.application_Status.toLowerCase()=='inprogress'), 
                                'badge badge-secondary':(data.item.application_Status.toLowerCase()=='paymentpending'),
                                'pendingCSSSClass':(data.item.application_Status.toLowerCase()=='paymentpending'),
                                'badge badge-info':(data.item.application_Status.toLowerCase()=='paymentsuccessful') ,
                                'badge badge-danger':(data.item.application_Status.toLowerCase()=='admissionrejected'),
                                'applicationNotEligibleStudent':(data.item.application_Status.toLowerCase()=='noteligible')}]">
                                {{data.item.applicationStatus}} 
                                 <!-- {{data.item.paymentPendingText}} -->
                               </span>
                               <span>
                                   <img 
                                    src="@/assets/images/erflog/info_icon.png"
                                    alt="info" 
                                    v-if='data.item.applicationStatus.toLowerCase()=="payment pending"'
                                    class="action_icon"
                                    @click="pendingTextModal=data.item.paymentPendingText,$bvModal.show('pendingInfo')"
                                   />
                               </span>
                            </template>
                            <template #cell(admissionStatus)="data">
                                <div v-if="application.applicationClose">
                                    <span v-if="data.item.applicationStatus == 'Admission Offered' && data.item.courseUniqueId == application.finalSelected.courseUniqueId">
                                        <a href='#'>Selected</a>
                                    </span>
                                </div>
                                <div v-else>
                                    <span v-if='data.item.applicationStatus.toLowerCase()=="payment pending"'>
                                    <a href='#' @click="$refs.uploadPhoto.click()">Upload Pay.Photo</a>                                   
                                        <span v-if="payFileError.error!==''">
                                            <small v-show="payFileError.error!==''&&payFileError.ind==data.index">
                                                {{payFileError.error}}
                                                </small>
                                        </span>                                    
                                    <input type="file" @change="uploadePayPhoto($event,data)" ref='uploadPhoto' style="visibility: hidden;display: none;">
                                    </span>
                                    <span v-if="data.item.applicationStatus == 'Admission Offered' && selection == false">                                   
                                    <b-button class="btn btn-icon btn-outline-primary" @click.prevent="()=>{finalSelectedCollege=data; $bvModal.show('modal-select-college');}">Select</b-button>
                                    </span>
                                </div>
                            </template>
                            </b-table>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>       
    </div>
    <b-spinner variant="primary" v-if="isLoader" large class="pageLoadSpinners"/>

    <!--select college -->
    <b-modal
    id="modal-select-college"
    cancel-variant="outline-secondary"
    ok-title="Yes"
    cancel-title="No"
    centered
    title=""
    :no-close-on-backdrop="true"
    @hidden="finalSelectedCollege=''"
    @ok="(e)=>{e.preventDefault(); selectCollegeForAdmission()}"
    >
    <div class="create_account_img">
    <img src="@/assets/images/erflog/Approve_Popup.png" alt="student">
    </div>
    <div style="text-align: center;">
        <label class="s_modal_title">Are you sure?</label>
        <p><span>Do you want to Select this University?</span></p>
    </div>
    </b-modal>

    <!-- PENDING TEXT -->
    <b-modal
    id="pendingInfo"
    cancel-variant="outline-secondary"
    centered
    title="Note"
    :no-close-on-backdrop="true"
    @hidden="pendingTextModal=''"    
    >   
    <div style="word-break: break-word;">
        <p><span>{{pendingTextModal}}</span></p>
    </div>
    </b-modal>
</div>
</template>
<script>
import {
  BCard, BRow, BCol, BTabs, BTab, BTable, BFormGroup, BFormInput, BButton, BSpinner,VBTooltip
} from 'bootstrap-vue'
import { dbCollections } from '@/utils/firebaseCollection'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import firebase from '@/utils/firebaseInit'
import firebaseTimeStamp from 'firebase'
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import {addHistoryObjectToApplication} from '@/utils/addHistoryOfApplication.js'
import applicationNoteManagement from '@/views/apps/student/applicationNoteManagement.vue'
const db = firebase.firestore()
export default {
    directives: {
        'b-tooltip':VBTooltip
    },
    components: {   
        BCard,
        BRow,
        BCol,
        BTabs,
        BTab,
        BTable,
        BFormGroup,
        BFormInput,
        BButton,
        BSpinner,
        applicationNoteManagement
    },
    data(){
        return{
            savingAdmission: false,
            pendingTextModal:'',
            application:{},
            shorlistedCollege:[],
            allSuggestedCollege:[],
            allCollegeShortlist:[],
            columns:[
                {key:'isSelect',label:''},
                // {key:'no',label:'No.'},
                // {key:'cllgName',label:'College Name', sortable: true},
                {key:'uniName',label:'University Name', sortable: true},
                // {key:'fieldName',label:'Field Name', sortable: true},
                {key:'courceName',label:'Course Name', sortable: true},
                {key:'modeofApp',label:'Mode of Application', sortable: true},
                {key:'intake',label:'Intake', sortable: true}
            ],
            shortListcolumns:[
                // {key:'no',label:'No.', sortable: true},
                {key:'isSelect',label:''},
                // {key:'cllgName',label:'College Name', sortable: true},
                {key:'uniName',label:'University Name', sortable: true},
                {key:'courceName',label:'Course Name', sortable: true},
                // {key:'fieldName',label:'Field Name', sortable: true},
                {key:'modeofApp',label:'Mode of Application', sortable: true},                
                {key:'applicationDeadLine',label:'Application DeadLine', sortable: true},
                {key:'examsScores',label:'English Scores', sortable: true},
            ],
            sortListOrder:['pending','notEligible','inProgress','paymentPending','paymentSuccessful','applicationSubmitted','admissionSuccessful','admissionRejected'],
            filter: null,
            filterOn: [],
            totalRows: 1,
            currentPage: 1,
            allSelect:false,
            errorShortList:'',
            payFiles:[],
            payFileError:{
                ind:0,
                error:''
            },
            selection:false,
            addmissionSelected:null,
            finalFlag:false,
            finalSelectedCollege:'',            
            isSpinner:false, //PAGE SPINNER
            isLoader:false, //PAGE LOAD SPINNER
            previousShortListedCollege:[]
        }
    },
    watch: {
    $route: function () {
        this.getData();          
    }
    },
    created(){
        this.getData();
        if(this.$route.meta.resource!="ACL")
        {
            this.columns.push({key:'applicationFee',label:'Application Fee', sortable: true})
            this.columns.push({key:'tutionFee',label:'Tution Fee', sortable: true})
            this.columns.push({key:'applicationDeadLine',label:'Application DeadLine', sortable: true})
            this.columns.push({key:'germanLanguage',label:'German Language', sortable: true})
            this.columns.push({key:'examsScores',label:'English Scores', sortable: true})
            this.columns.push({key:'remark',label:'Remark', sortable: true})

            this.shortListcolumns.push({key:'applicationFee',label:'Application Fee', sortable: true})
            this.shortListcolumns.push({key:'tutionFee',label:'Tution Fee', sortable: true})
            this.shortListcolumns.push({key:'applicationStatus',label:'Application Status', sortable: true})
            this.shortListcolumns.push({key:'germanLanguage',label:'German Language', sortable: true})
            this.shortListcolumns.push({key:'remark',label:'Remark', sortable: true})
            this.shortListcolumns.push({key:'admissionStatus',label:''})
        }
        else
        {
            this.columns.push({key:'applicationFee',label:'Application Fee', sortable: true})
            this.columns.push({key:'tutionFee',label:'Tution Fee', sortable: true})
            this.columns.push({key:'applicationDeadLine',label:'Application DeadLine', sortable: true})
            this.columns.push({key:'examsScores',label:'English Scores', sortable: true})
            // this.columns.push({key:'germanLanguage',label:'German Language', sortable: true})
            this.columns.push({key:'remark',label:'Remark', sortable: true})

            this.shortListcolumns.push({key:'applicationFee',label:'Application Fee', sortable: true})
            this.shortListcolumns.push({key:'tutionFee',label:'Tution Fee', sortable: true})
            this.shortListcolumns.push({key:'applicationStatus',label:'Application Status', sortable: true})
            // this.shortListcolumns.push({key:'germanLanguage',label:'German Language', sortable: true})
            this.shortListcolumns.push({key:'remark',label:'Remark', sortable: true})
            this.shortListcolumns.push({key:'admissionStatus',label:''})
            this.shortListcolumns.push({key:'sortListDate',label:'Sort List Date', sortable: true})
        }

        setTimeout(() => {
            this.$root.$emit('startSpinner');
        });
    },
    methods:{
        checkAllClgStatuses()
        {
            var self = this

            var found = false

            // console.log('slc',self.shorlistedCollege)

            self.shorlistedCollege.forEach(data=>{
                if(data.clgStatus!='pending' && data.clgStatus!='')
                {
                    found = true
                }
            })

            return found
        },
        getFieldAndCourse(data,appData,cb){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.FIELDANDCOURSE,data.fieldName.id,uniRes=>{
                    // obj.universityName = uniRes.universityName
                    if(Object.keys(uniRes).length > 0){
                    if(uniRes.isDeleted == false){
                        if(uniRes.courses.length > 0){
                            let searchName = uniRes.courses.findIndex(values=>{
                                // console.log(values.id, "==", appData.courseCode)
                                return values.id == appData.courseCode
                            })
                            if(searchName > -1){
                                let datas = uniRes.courses[searchName];
                                datas.fieldName = uniRes.fieldName 
                                cb(datas)
                            }else{
                                cb(false)
                            }
                        }else{
                        cb(false)
                        }
                    }else{
                        cb(false)
                    }
                    }else{
                        cb(false)
                    }
            })
        },     
        getCllgAndUniData(data,cb){
            let self = this;
            let obj = {}
            // console.log(data,"appData")
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.COLLEGES,data.id,res=>{
                obj.cllgName = res.collegeName; 
                if(res.courses.length > 0){
                    let searchInd = res.courses.findIndex(courseEle=>{
                        return courseEle.fieldName.id == data.fieldId && courseEle.courseName.id == data.courseCode
                    })
                    if(searchInd > -1){
                        obj.courseFinalData = res.courses[searchInd];
                    }else{
                        obj.courseFinalData = null
                    }    
                }
                // console.log(data,"Nice456")
                getQuery.getDataFromRootCollectionWithDocID(dbCollections.UNIVERSITIES,res.universityId,uniRes=>{
                    obj.universityName = uniRes.universityName

                    if(res.courses !== undefined && res.courses.length > 0){
                    //     //HERE WHEN COLLEGE CONTAIN COURSES
                        obj.courseAndField = [];
                        // console.log(res.courses,"res.courses")
                        let fileArry = res.courses
                        let count = 0;
                        const uploadFileFunction = (row) => {
                        if(count >= fileArry.length){  
                            cb(obj)
                            return;                 
                        }
                            self.getFieldAndCourse(row,data,(saveRes)=>{
                                // console.log(saveRes,"courseData")
                                let courseData = {
                                    courseName :'',
                                    courseId :'',
                                    fieldName :'',
                                    'applicationFee':'',
                                    'tutionFee':'',
                                }
                                if(saveRes !== false){
                                    courseData = {
                                        courseName :saveRes.name,
                                        courseId :saveRes.id,
                                        fieldName :saveRes.fieldName,
                                    }
                                    obj.courseAndField = courseData;
                                    // cb(obj)
                                }
                                if(count <= Number(fileArry.length - 1)){
                                count += 1;
                                uploadFileFunction(fileArry[count]);
                                }else{
                                    cb(obj)
                                }
                            });              
                        }
                        uploadFileFunction(fileArry[count]);
                     }else{
                        cb(obj)
                     }    
                })
            })
        },
        getData(){
            let id = this.$route.params.id;
            this.isLoader = true;
            this.$root.$emit('startSpinner');
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.APPLICATIONS,id,res=>{
                this.application = res;     
                this.selection = this.application.applicationClose ? this.application.applicationClose : false;
                var tmpshorlistedCollege = [];   
                var tampallCollegeShortlist = [];
                this.shorlistedCollege = [];
                this.allCollegeShortlist = [];      
                this.allSuggestedCollege = res.selectedCollege;  //Suggested Course               
                if(Object.keys(this.application).length > 0){        
                    if(this.application.selectedCollege.length > 0){
                        let count = 0;
                        let fileArry = this.application.selectedCollege; //SPECIFIC APP. SUGGESTEDLIST  
                        const uploadFileFunction = (row) => {
                            if(count >= fileArry.length){
                                setTimeout(()=>{
                                    this.checkselected()
                                    this.allCollegeShortlist = tampallCollegeShortlist;
                                    this.shorlistedCollege = tmpshorlistedCollege.sort((a,b)=>{
                                        return b.isSelect - a.isSelect
                                    })
                                    // this.shorlistedCollege = tmpshorlistedCollege.sort((a, b) => {
                                    //     return this.sortListOrder.indexOf(b.clgStatus) - this.sortListOrder.indexOf(a.clgStatus);
                                    // });  
                                    this.previousShortListedCollege = [...this.allCollegeShortlist];
                                    // console.log(this.allCollegeShortlist,"%%%allCollegeShortlist%%%")
                                    this.isLoader = false
                                    this.$root.$emit('stopSpinner'); 
                                },1000);
                            } else {
                                // this.getCllgAndUniData(row,(saveRes)=>{   
                                    let datafieldName = fileArry[count].dispFieldName;
                                    let dataCourseName = fileArry[count].dispCourseName;
                                // if(saveRes.courseAndField !== undefined && Object.keys(saveRes.courseAndField).length > 0){
                                //     datafieldName = saveRes.courseAndField.fieldName;
                                //     dataCourseName = saveRes.courseAndField.courseName;
                                // } 
                                    if(fileArry[count].dispIntakeDeadline.length > 0){
                                        fileArry[count].dispIntakeDeadline.forEach(deadElement => {
                                            deadElement.disDate = moment(new Date(deadElement.date)).format('DD MMM, YYYY') 
                                        });                               
                                    }

                                    // console.log("SaveRes",saveRes)
                                    let suggestedObj = {
                                        'no':Number(count + 1),
                                        'isSelect':false,
                                        'cllgName':fileArry[count].college_name,
                                        'uniName':fileArry[count].university_name,
                                        'fieldName':datafieldName,
                                        'courceName':dataCourseName,
                                        'fieldId':fileArry[count].fieldId,
                                        'courseCode':fileArry[count].courseCode,
                                        'modeofApp':fileArry[count].dispmodeOfApplication,
                                        'intake':fileArry[count].dispIntake,
                                        'applicationDeadLine':fileArry[count].dispIntakeDeadline,
                                        'examsScores': fileArry[count].examsScore,
                                        'cllgId': fileArry[count].id,
                                        'clgStatus':'',

                                        'applicationFee': fileArry[count].applicationFee,
                                        'tutionFee': fileArry[count].tutionFee,
                                        'courseUniqueId': fileArry[count].courseUniqueId,
                                        'germanLanguage':fileArry[count].germanLanguage,
                                        'remark':fileArry[count].remark,
                                    }
                                    // if(saveRes.courseFinalData !== null){
                                        // console.log(fileArry[count].examsScore,"fileArry[count].examsScore")
                                        // suggestedObj.modeofApp = saveRes.courseFinalData.modeOfApplication;
                                        // suggestedObj.intake = saveRes.courseFinalData.intake;
                                        if(fileArry[count].dispIntakeDeadline.length > 0){
                                            fileArry[count].dispIntakeDeadline.forEach(deadlineDate=>{
                                                deadlineDate.disDate = moment(new Date(deadlineDate.date)).format('DD MMM')
                                            })
                                            suggestedObj.applicationDeadLine = fileArry[count].dispIntakeDeadline;
                                        }
                                        // suggestedObj.examsScores = fileArry[count].exams
                                    // }
                                    //SHORTLISTED SET
                                    // console.log(" helee2 res.selectedCollege:",res.selectedCollege)
                                    if(this.application.shortListedColleges !== undefined && this.application.shortListedColleges.length > 0){
                                        this.application.shortListedColleges.forEach(values=>{
                                            if(values.id == fileArry[count].id){ //GET SAME COLLEGE
                                                if(values.courseUniqueId == fileArry[count].courseUniqueId){ //MATCH FIELD ID AND COURSE CODE
                                                    suggestedObj.isSelect = true; 
                                                    let statusVariant  = values.statusVariant ? values.statusVariant : '';
                                                    let statusText = values.statusText ? values.statusText : '';                              
                                                    if(statusVariant == '' || statusText == ''){
                                                        if(values.status == 'pending'){
                                                            statusVariant = 'primary';
                                                            statusText = 'Pending';
                                                        }
                                                        if(values.status == 'inProgress'){
                                                            statusVariant = 'warning';
                                                            statusText = 'In Progress';
                                                        }
                                                        if(values.status == 'paymentPending'){
                                                            statusVariant = 'secondary'
                                                            statusText = 'Payment Pending';
                                                        }
                                                        if(values.status == 'paymentSuccessful'){
                                                            statusVariant = 'info'
                                                            statusText = 'Payment Successful';
                                                        }
                                                        if(values.status == 'applicationSubmitted'){
                                                            statusVariant = 'dark'
                                                            statusText = 'Application Submitted';
                                                        }
                                                        if(values.status == 'admissionSuccessful'){
                                                            statusVariant = 'success'
                                                            statusText = 'Admission Offered';
                                                        }
                                                        if(values.status == 'admissionRejected'){
                                                            statusVariant = 'danger'
                                                            statusText = 'Rejection';
                                                        }   
                                                        if(values.status == 'notEligible'){
                                                            statusVariant = 'dark'
                                                            statusText = 'Not Eligible';
                                                        }   
                                                    }
                                                    let disStatus = values.status ? values.status : 'pending' ;                                                                                  
                                                    let statusArray =  [
                                                    {text:'Pending',value:'pending'},
                                                    {text:'Not Eligible',value:'notEligible'},
                                                    {text:'In Progress',value:'inProgress'},
                                                    {text:'Admission Offered',value:'admissionSuccessful'},
                                                    {text:'Rejection',value:'admissionRejected'},
                                                    {text:'Application Submitted',value:'applicationSubmitted'},
                                                    {text:'Payment Pending',value:'paymentPending'},
                                                    {text:'Payment Successful',value:'paymentSuccessful'}
                                                    ];
                                                    let statusInd = statusArray.findIndex(statusEle=>{
                                                    return values.status == statusEle.value 
                                                    })
                                                    if(statusInd > -1){
                                                        disStatus = statusArray[statusInd].text;
                                                    }

                                                    let shortListObj = {
                                                        // 'no':Number(this.allCollegeShortlist.length),
                                                        'no':tampallCollegeShortlist.length,
                                                        'isSelect':true,                                                
                                                        'cllgName':values.college_name,
                                                        'uniName':values.university_name,
                                                        'fieldName':datafieldName,
                                                        'application_Id':res.id,
                                                        'courceName':dataCourseName,
                                                        'fieldId':fileArry[count].fieldId,
                                                        'courseCode':fileArry[count].courseCode,
                                                        'modeofApp':fileArry[count].dispmodeOfApplication,
                                                        'intake':fileArry[count].dispIntake,
                                                        'sortListDate':values.sortListDate ? moment(values.sortListDate.seconds * 1000).format('DD MMM YYYY hh:mm A') : '-',
                                                        'applicationDeadLine':fileArry[count].dispIntakeDeadline,
                                                        'examsScores': fileArry[count].examsScore,
                                                        'cllgId': fileArry[count].id,
                                                        'applicationStatus':disStatus,
                                                        'admissionStatus':'',
                                                        'application_Status': values.status,
                                                        'shortlist_status': values.status,
                                                        'statusVariant': statusVariant,
                                                        'statusText': statusText,
                                                        'applicationFee': values.applicationFee,
                                                        'tutionFee': values.tutionFee,
                                                        'paymentPendingText':values.paymentPendingText ? values.paymentPendingText : '',
                                                        'courseUniqueId': values.courseUniqueId,
                                                        'germanLanguage':values.germanLanguage,
                                                        'remark':values.remark,
                                                    }

                                                    // if(values.courseFinalData !== null){
                                                    //     shortListObj.modeofApp = saveRes.courseFinalData.modeOfApplication;
                                                    //     shortListObj.intake = saveRes.courseFinalData.intake;
                                                    //     shortListObj.applicationDeadLine = saveRes.courseFinalData.applicationDeadline;
                                                    //     shortListObj.examsScores = saveRes.courseFinalData.exams
                                                    // }

                                                    suggestedObj.clgStatus = values.status
                                                    // console.log(shortListObj,"shortListObj")
                                                    tampallCollegeShortlist.push(shortListObj)
                                                }
                                            }
                                        })
                                    }
                                    // this.shorlistedCollege.push(suggestedObj) //Suggested Course
                                    tmpshorlistedCollege.push(suggestedObj);
                                    count += 1;
                                    uploadFileFunction(fileArry[count]);
                                // });   
                            }           
                        }
                        // this.shorlistedCollege = [];   
                        // this.allCollegeShortlist = [];
                        uploadFileFunction(fileArry[count]); //Suggested CourseS
                        // this.isLoader = false
                        this.$root.$emit('stopSpinner');     
                    }                                                       
                }else{
                    this.isLoader = false
                    this.$root.$emit('stopSpinner');
                }                
            })
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        },
        saveShorlistedColleges(){
            let isValid = false
            let shortListArry = [];
            let currentUser = firebase.auth().currentUser;
            let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
            if(this.shorlistedCollege.length > 0){
                    this.shorlistedCollege.forEach(ele=>{
                        if(ele.isSelect == true){
                            isValid = true
                        }
                    })
                    if(isValid == true){
                      let id = this.$route.params.id;
                        if(this.shorlistedCollege.length > 0){
                            this.$axios.get(dbCollections.APIURL + 'api/v1/getTime')
                            .then(data => {
                                let updateVal = false;
                                this.isSpinner = true;
                                let currentUtcString = data?.data?.utc_datetime ? data?.data?.utc_datetime : new Date().toISOString();
                                this.shorlistedCollege.forEach(val=>{ //Suggested Course
                                    if(val.isSelect == true){
                                        //   console.log(val,"JYOTI YADAV")
                                        this.allSuggestedCollege.forEach(elem=>{
                                            if(elem.courseUniqueId == val.courseUniqueId)
                                            {
                                                let findIndex = this.allCollegeShortlist.findIndex(dataArry=>{ //Shorlist Course
                                                    return elem.courseUniqueId == dataArry.courseUniqueId
                                                })
                                                if(findIndex > -1){
                                                    elem.status = this.allCollegeShortlist[findIndex].shortlist_status;
                                                    elem.statusText =  this.allCollegeShortlist[findIndex].statusText;
                                                    elem.statusVariant = this.allCollegeShortlist[findIndex].statusVariant;                                                               
                                                }else{
                                                    elem.status = 'pending';
                                                    elem.statusVariant = 'primary';
                                                    elem.statusText = 'Pending';
                                                }
                                                let index = this.previousShortListedCollege.findIndex((element)=>{
                                                    return (element.courseUniqueId == val.courseUniqueId)
                                                })
                                                if(index == -1){
                                                    elem.sortListDate = new Date(currentUtcString);
                                                } else {
                                                    elem.sortListDate = (this.previousShortListedCollege[index].sortListDate && this.previousShortListedCollege[index].sortListDate !== '-') ? new Date(this.previousShortListedCollege[index].sortListDate) : '';
                                                }
                                                if(elem.sortListDate == 'Invalid Date') {
                                                    elem.sortListDate = new Date(currentUtcString);
                                                }
                                                //HISTORY OBJECT
                                                
                                                let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                                        return (element.courseUniqueId == val.courseUniqueId) && elem.courseUniqueId == element.courseUniqueId
                                                    })
                                                if(findIndex2 == -1){
                                                    let object = {
                                                        createdAt: new Date(currentUtcString),
                                                        message: `<span><b>${currentUserObject.fullName}</b> has added this to sortlist list</span>`,
                                                        userId: currentUser.uid,
                                                        fID: elem.fieldId,
                                                        cId: elem.id,
                                                        courseCode: elem.courseCode,
                                                        avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                                    }
                                                    // console.log("findIndex2--------------if---------",object,this.applicationData.id)
                                                    addHistoryObjectToApplication(object,this.$route.params.id).catch((error)=>{
                                                        console.error(error);
                                                    })
                                                    updateVal = true;
                                                }
                                                shortListArry.push(elem)  
                                            }
                                        })
                                    } else {
                                        let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                            return (element.courseUniqueId == val.courseUniqueId)
                                        })
                                        if(findIndex2 !== -1){
                                            let object = {
                                                createdAt: new Date(currentUtcString),
                                                message: `<span><b>${currentUserObject.fullName}</b> has removed this from sortlist list</span>`,
                                                userId: currentUser.uid,
                                                fID: val.fieldId,
                                                cId: val.cllgId,
                                                courseCode: val.courseCode,
                                                avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                            }
                                            addHistoryObjectToApplication(object,this.$route.params.id).catch((error)=>{
                                                console.error(error);
                                            })
                                            updateVal = true;
                                            // console.log(this.previousShortListedCollege[findIndex2],"findIndex2--------------if----------------",object,this.applicationData.id)
                                        }
                                    }
                                })
                                if(shortListArry.length > 0){
                                    if(updateVal == true) {
                                        let updateObj = {
                                            shortListedColleges: shortListArry,
                                            updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                        }
                                        updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,id,updateObj,res=>{
                                            //   console.log(res);
                                            this.isSpinner = false;
                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: 'ShortList update Successfully',
                                                    icon: 'CoffeeIcon',
                                                    variant: 'success',                               
                                                },
                                            })
                                            let notificationObj = {
                                                title: `Shortlist Course`,
                                                message: `${currentUserObject.fullName} has updated the shortlist of courses for application ${this.application.applicationNumber}`,
                                                type: 'applicationSortlistCollege',
                                                userId: currentUser.uid,
                                                applicationId: this.application.id,
                                                currentUser:firebase.auth().currentUser.uid
                                            }
                                            if(currentUserObject.role == 'student') {
                                                notificationObj.type = 'applicationSortlistCollege';
                                            } else {
                                                notificationObj.type = 'applicationSortlistCollegeAdmin';
                                            }
                                            this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                                            }).catch((error)=>{
                                                console.error(error);
                                            })
                                            //   this.$router.push({name:'studentapplicationMgmt'}) 
                                        })
                                    } else {
                                        this.isSpinner = false;
                                    }
                                }else{
                                    this.isSpinner = false;
                                    this.errorShortList = 'please select some from Suggested Courses';
                                }
                            }).catch(()=>{
                                let updateVal = false;
                                let currentUtcString = new Date().toISOString();
                                this.shorlistedCollege.forEach(val=>{ //Suggested Course
                                    if(val.isSelect == true){
                                        //   console.log(val,"JYOTI YADAV")
                                        this.allSuggestedCollege.forEach(elem=>{
                                            if(elem.courseUniqueId == val.courseUniqueId)
                                            {
                                                // console.log(elem,"elem")
                                                let findIndex = this.allCollegeShortlist.findIndex(dataArry=>{ //Shorlist Course
                                                    return elem.courseUniqueId == dataArry.courseUniqueId
                                                })
                                                if(findIndex > -1){
                                                    elem.status = this.allCollegeShortlist[findIndex].shortlist_status;
                                                    elem.statusText =  this.allCollegeShortlist[findIndex].statusText;
                                                    elem.statusVariant = this.allCollegeShortlist[findIndex].statusVariant;                                                               
                                                }else{
                                                    elem.status = 'pending';
                                                    elem.statusVariant = 'primary';
                                                    elem.statusText = 'Pending';
                                                }
                                                let index = this.previousShortListedCollege.findIndex((element)=>{
                                                    return (element.courseUniqueId == val.courseUniqueId)
                                                })
                                                if(index == -1){
                                                    elem.sortListDate = new Date(currentUtcString);
                                                } else {
                                                    elem.sortListDate = (this.previousShortListedCollege[index].sortListDate && this.previousShortListedCollege[index].sortListDate !== '-') ? new Date(this.previousShortListedCollege[index].sortListDate) : '';
                                                }
                                                if(elem.sortListDate == 'Invalid Date') {
                                                    elem.sortListDate = new Date(currentUtcString);
                                                }
                                                //HISTORY OBJECT
                                                
                                                let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                                        return (element.courseUniqueId == val.courseUniqueId) && elem.courseUniqueId == element.courseUniqueId
                                                    })
                                                if(findIndex2 == -1){
                                                    let object = {
                                                        createdAt: new Date(currentUtcString),
                                                        message: `<span><b>${currentUserObject.fullName}</b> has added this to sortlist list</span>`,
                                                        userId: currentUser.uid,
                                                        fID: elem.fieldId,
                                                        cId: elem.id,
                                                        courseCode: elem.courseCode,
                                                        avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                                    }
                                                    // console.log("findIndex2--------------if---------",object,this.applicationData.id)
                                                    addHistoryObjectToApplication(object,this.$route.params.id).catch((error)=>{
                                                        console.error(error);
                                                    })
                                                    updateVal = true;
                                                }
                                                shortListArry.push(elem)  
                                            }
                                        })
                                    } else {
                                        let findIndex2 = this.previousShortListedCollege.findIndex((element)=>{
                                            return (element.courseUniqueId == val.courseUniqueId)
                                        })
                                        if(findIndex2 !== -1){
                                            let object = {
                                                createdAt: new Date(currentUtcString),
                                                message: `<span><b>${currentUserObject.fullName}</b> has removed this from sortlist list</span>`,
                                                userId: currentUser.uid,
                                                fID: val.fieldId,
                                                cId: val.cllgId,
                                                courseCode: val.courseCode,
                                                avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                            }
                                            addHistoryObjectToApplication(object,this.$route.params.id).catch((error)=>{
                                                console.error(error);
                                            })
                                            updateVal = true;
                                            // console.log(this.previousShortListedCollege[findIndex2],"findIndex2--------------if----------------",object,this.applicationData.id)
                                        }
                                    }
                                })
                                if(shortListArry.length > 0){
                                    if(updateVal == true) {
                                        let updateObj = {
                                            shortListedColleges: shortListArry,
                                            updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                        }
                                        updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,id,updateObj,res=>{
                                            //   console.log(res);
                                            this.isSpinner = false;
                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: 'ShortList update Successfully',
                                                    icon: 'CoffeeIcon',
                                                    variant: 'success',                               
                                                },
                                            })
                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: 'ShortList update Successfully',
                                                    icon: 'CoffeeIcon',
                                                    variant: 'success',                               
                                                },
                                            })
                                            let notificationObj = {
                                                title: `Shortlist Course`,
                                                message: `${currentUserObject.fullName} has updated the shortlist of courses for application ${this.application.applicationNumber}`,
                                                type: 'applicationSortlistCollege',
                                                userId: currentUser.uid,
                                                applicationId: this.application.id,
                                                currentUser:firebase.auth().currentUser.uid
                                            }
                                            this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                                            }).catch((error)=>{
                                                console.error(error);
                                            })
                                            //   this.$router.push({name:'studentapplicationMgmt'}) 
                                        })
                                    }
                                }else{
                                    this.isSpinner = false;
                                    this.errorShortList = 'please select some from Suggested Courses';
                                }
                            })
                        }  
                    }else{
                        this.isSpinner = false;
                        this.errorShortList = 'please select some from Suggested Courses';
                    }
            }else{
            this.isSpinner = false;
            }
            // this.isSpinner = false;
        },
        selectAll(){
            if(this.allSelect !== false){
                if(this.shorlistedCollege.length > 0){
                    this.shorlistedCollege.forEach(ele=>{
                        ele.isSelect = true;
                    })
                    this.errorShortList = ""
                }
            }else{
                if(this.shorlistedCollege.length > 0){
                    this.shorlistedCollege.forEach(ele=>{
                        ele.isSelect = false;
                    })
                    this.errorShortList = 'please select some from Suggested Courses';
                }
            }
        },
        checkselected(){
            let isChecked = false;
             if(this.shorlistedCollege.length > 0){
                this.shorlistedCollege.forEach(ele=>{
                        if(ele.isSelect == ''||ele.isSelect == false || ele.isSelect == undefined){
                            isChecked = true
                        }
                })
                if(isChecked == false){
                    this.allSelect = true;
                }
             }
        },
        suggestedChange(){   
            let isValid = false
            let allChecked = true       
                if(this.shorlistedCollege.length > 0){
                    this.shorlistedCollege.forEach(ele=>{
                        if(ele.isSelect == true){
                            isValid = true    
                        }else{
                            this.allSelect = false    
                        }
                        if(ele.isSelect !== true){
                            allChecked = false
                        }
                    })
                    if(allChecked == true){
                        this.allSelect = allChecked;
                    }
                    if(isValid == false){
                    this.allSelect = false    
                    this.errorShortList = 'please select some from Suggested Courses';
                    }else{
                        this.errorShortList = '';
                    }
                    
                }          
        },
        uploadPayFile(data,cb){
            // console.log(data,"DATA")
            var ext = data.name.split('.')
            ext = ext[ext.length-1]
            if(data.type !== 'image/png' && data.type !== 'image/jpg' && data.type !== 'image/jpeg' && ext !== 'pdf'){
                this.payFileError.error = "upload only file type jpg,png,jpeg"
                // console.log("ERROR")
                this.$root.$emit('showToastMessage','upload only file type jpg,png,jpeg,pdf','danger')
                this.isSpinner = false
            }else{
            this.payFileError = {
               ind:null,
               error:'',
            }    
            // console.log(data,"JYOTIi")
            uploadImageFile('shortlistPayment',data.name,data,res=>{
                cb(res)
            })
            }
        },
        uploadePayPhoto(event,data){
            if(this.isSpinner == false){
                this.payFiles = event.target.files;
                let currentUser = firebase.auth().currentUser;
                let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
                this.isSpinner = true;
                this.payFileError = {
                    ind:Number(data.index)-1,
                    error:''
                };
                if(this.payFiles.length > 0){
                    let count = 0;
                    let fileArry = this.payFiles;
                    const uploadFileFunction = (row) => {
                    if(count >= fileArry.length){ 
                        this.isSpinner = false;   
                        return;               
                    }
                        this.uploadPayFile(row,(saveRes)=>{
                            this.application.shortListedColleges.forEach(elements=>{
                                if(elements.courseUniqueId == data.item.courseUniqueId){
                                    elements.uploadPayFile = saveRes;
                                    elements.status='paymentSuccessful';
                                    elements.statusText='Payment Successful';
                                    elements.statusVariant='info';

                                    db
                                    .collection(dbCollections.APPLICATIONS)
                                    .doc(data.item.application_Id)
                                    .update({
                                        'shortListedColleges':this.application.shortListedColleges,
                                        'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                                    })
                                    .then(() =>{
                                        let object = {
                                            createdAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                            message: `<span><b>${currentUserObject.fullName}</b> has changed status to <b>Payment Successful</b></span>`,
                                            userId: currentUser.uid,
                                            fID: data.item.fieldId,
                                            cId: data.item.cllgId,
                                            courseCode: data.item.courseCode,
                                            avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                        }
                                        // console.log("findIndex2--------------if---------",object,this.applicationData.id)
                                        addHistoryObjectToApplication(object,this.$route.params.id).catch((error)=>{
                                            console.error(error);
                                        })
                                        let notificationObj = {
                                            title:"Payment Successful",
                                            message: `${currentUserObject.fullName} has uploaded a payment slip for the course name ${data.item.courceName} and the college name ${data.item.cllgName}.`,
                                            type: 'paymentSuccessfulDocument',
                                            userId: currentUser.uid
                                        }
                                        this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                        }).catch((error)=>{
                                            console.error(error);
                                        });
                                        this.$root.$emit('showToastMessage','Pay photo uploaded successfully.','success')
                                    }).catch((error)=>{
                                        this.$root.$emit('showToastMessage',error.message,'danger')
                                    })    
                                }                            
                            })
                            count += 1;
                            uploadFileFunction(fileArry[count]);
                        });              
                    }
                    uploadFileFunction(fileArry[count]);
                }else{
                    this.isSpinner = false
                }
            }
        },
        selectCollegeForAdmission()
        {
            var self = this
            if(self.savingAdmission) return;
            // console.log(self.finalSelectedCollege,firebase.auth().currentUser)
            self.selection = true;
            self.savingAdmission = true;
            self.addmissionSelected = self.finalSelectedCollege;
            let currentUserObject = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
            // console.log(self.addmissionSelected,"self.addmissionSelected")
            //Update university Name in student document
            let finalSelectedObj = {...self.addmissionSelected.item,'selectedTime':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()};
             db
                .collection(dbCollections.APPLICATIONS)
                .doc(self.addmissionSelected.item.application_Id)
                .update({
                    'finalSelected':finalSelectedObj,
                    'applicationClose':true,
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                })
                .then(() =>{
                    self.$root.$emit('showToastMessage','Application selected successfully.','success') 
                      db
                        .collection(dbCollections.USERS)
                        .doc(firebase.auth().currentUser.uid)
                        .update({
                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            selectedCollege:self.finalSelectedCollege.item.cllgName,
                            selectedCollegeId:self.finalSelectedCollege.item.cllgId,
                            selectedFieldId:self.finalSelectedCollege.item.fieldId,
                            selectedCourseId:self.finalSelectedCollege.item.courseCode,
                        })
                        .then(() =>{
                            db.collection(dbCollections.USERS).doc(firebase.auth().currentUser.uid).get().then((user)=>{
                                let notificationObj = {
                                        name: user.data().firstName,
                                        imgUrl: user.data().imageUrl,
                                        selectedUniversity: self.addmissionSelected.item.cllgName,
                                        userId: firebase.auth().currentUser.uid
                                }
                                let object = {
                                    createdAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                    message: `<span><b>${currentUserObject.fullName}</b> has selected <b>${self.addmissionSelected.item.cllgName}</b> for admission</span>`,
                                    userId: firebase.auth().currentUser.uid,
                                    fID: self.finalSelectedCollege.item.fieldId,
                                    cId: self.finalSelectedCollege.item.cllgId,
                                    courseCode: self.finalSelectedCollege.item.courseCode,
                                    avatar: currentUserObject.avatar ? currentUserObject.avatar : '',
                                }
                                // console.log("findIndex2--------------if---------",object,this.applicationData.id)
                                addHistoryObjectToApplication(object,self.addmissionSelected.item.application_Id).catch((error)=>{
                                    console.error(error);
                                })
                                let notificationObject = {
                                    title:"Admission Confirm",
                                    message: `${currentUserObject.fullName} has confirmed admission to ${self.addmissionSelected.item.cllgName}`,
                                    type: 'admissionConfirmed',
                                    userId: firebase.auth().currentUser.uid
                                }
                                this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObject).then(()=>{
                                }).catch((error)=>{
                                    console.error(error);
                                    this.isSpinner = false;
                                });
                                this.$axios.post(dbCollections.APIURL+'api/v1/admissionConfirmNotification',notificationObj).then(()=>{
                                }).catch((error)=>{
                                    console.error(error);
                                })
                                self.$root.$emit('showToastMessage','University selected successfully.','success')
                                self.$bvModal.hide('modal-select-college');
                                self.$nextTick(() => {
                                    self.savingAdmission = false
                                })
                            }).catch((error) =>{console.error(error)});
                        })
                        .catch(error=>{
                            self.$root.$emit('showToastMessage',error.message,'danger')
                        })
                    // self.$bvModal.hide('modal-select-college')
                })
                .catch(error=>{                    
                    console.log(error)
                })
          

            // setTimeout(()=>{
                
            // },1000)
        },
        selectedCollege(){
            // console.log(data,"HERE SEEE")

        }
    }
}
</script>
<style>
.addUniqClass {
    font-weight: 700;
    color: black;
}
.applicationNotEligibleStudent {
    border-color: #396060!important;
    background-color: #396060!important;
    color: white;
    padding: 8px 10px !important;
    border-radius: 10px !important;
}
.remarktext__elisis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 200px;
}
</style>